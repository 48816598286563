import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaTelegram } from 'react-icons/fa';
import './../../css/navbar/navbar.css';

const Navbar = () => {
    const [walletAddress, setWalletAddress] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (walletAddress) {
            navigate(`/portfolio?address=${walletAddress}`);
            setIsMenuOpen(false);
            setWalletAddress('');
        }
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                {/* Menu Toggle Button */}
                <button className="menu-toggle" onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>

                {/* Logo */}
                <NavLink to="/" className="navbar-logo">
                    <h1>ASXN</h1>
                </NavLink>

                {/* Navigation Links */}
                <div className={`navbar-links ${isMenuOpen ? 'active' : ''}`}>
                    <NavLink to="/all_metrics" onClick={() => setIsMenuOpen(false)}>All Metrics</NavLink>
                    <NavLink to="/symbol_wise_metrics" onClick={() => setIsMenuOpen(false)}>Symbol Metrics</NavLink>
                    <NavLink to="/vault_metrics" onClick={() => setIsMenuOpen(false)}>Vault Metrics</NavLink>
                    <NavLink to="/portfolio" onClick={() => setIsMenuOpen(false)}>Portfolio</NavLink>
                    <NavLink to="/staking" onClick={() => setIsMenuOpen(false)}>Validators</NavLink>
                    <a href="https://app.hyperliquid.xyz/join/ASXNLAB" target="_blank" rel="noopener noreferrer" onClick={() => setIsMenuOpen(false)}>Trade on Hyperliquid</a>

                    {/* Telegram Link (included in toggle menu for small screens) */}
                    <a href="https://t.me/+FbZod8E8mHdhYjk8" target="_blank" rel="noopener noreferrer" className="telegram-link">
                        <FaTelegram size={24} color="#4d81ea" />
                    </a>
                </div>

                {/* Search Form */}
                <div className="search-container">
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={walletAddress}
                            onChange={(e) => setWalletAddress(e.target.value)}
                            placeholder="Enter wallet address"
                            className="navbar_modern-input"
                        />
                        <button type="submit" className="navbar_analyze_button">Analyze</button>
                    </form>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
