import React from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid
} from 'recharts';

const RecentBlocksChart = ({ data }) => {
    const chartData = data.map(item => ({
        name: item.name || item.validator.slice(0, 6),
        recentBlocks: item.nRecentBlocks || 0
    })).sort((a, b) => b.recentBlocks - a.recentBlocks);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: '#000',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    borderRadius: '4px',
                    padding: '12px',
                    maxWidth: window.innerWidth < 640 ? '150px' : '200px',
                }}>
                    <p style={{
                        margin: '0',
                        color: '#fff',
                        fontSize: '12px',
                        wordBreak: 'break-word'
                    }}>{label}</p>
                    <p style={{
                        margin: '4px 0 0 0',
                        color: '#4d81ea',
                        fontSize: '12px'
                    }}>
                        Recent Blocks: {payload[0].value}
                    </p>
                </div>
            );
        }
        return null;
    };

    const CustomLegend = () => (
        <div className="chart-legend">
            <span style={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: '#4d81ea',
                marginRight: '6px'
            }}></span>
            Recent Blocks
        </div>
    );

    const chartConfig = {
        margin: {
            top: 20,
            right: 10,
            bottom: 100,
            left: 40
        },
        xAxisConfig: {
            angle: window.innerWidth < 640 ? -45 : -30,
            textAnchor: "end",
            interval: window.innerWidth < 640 ? 1 : 0,
            height: 50,
            tick: {
                fill: '#fff',
                fontSize: window.innerWidth < 640 ? 6 : 8,
                dy: 8
            }
        },
        yAxisConfig: {
            tick: {
                fill: '#fff',
                fontSize: 10
            },
            width: 45
        }
    };

    return (
        <div className="all_validators_chart-container">
            <h3 style={{
                color: '#fff',
                marginBottom: '20px',
                fontSize: '14px',
                fontWeight: 'normal',
                textAlign: 'center',
            }}>
                Recent Blocks by Validator
            </h3>
            <CustomLegend />
            <div style={{ width: '100%', height: 'calc(100% - 80px)', marginTop: '30px' }}>
                <ResponsiveContainer>
                    <AreaChart
                        data={chartData}
                        margin={chartConfig.margin}
                    >
                        <defs>
                            <linearGradient id="recentBlocksGradient" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="#4d81ea" stopOpacity={0.3} />
                                <stop offset="100%" stopColor="#4d81ea" stopOpacity={0.1} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={false}
                            stroke="rgba(255, 255, 255, 0.1)"
                        />
                        <XAxis
                            dataKey="name"
                            {...chartConfig.xAxisConfig}
                        />
                        <YAxis {...chartConfig.yAxisConfig} />
                        <Tooltip content={<CustomTooltip />} />
                        <Area
                            type="monotone"
                            dataKey="recentBlocks"
                            stroke="#4d81ea"
                            strokeWidth={2}
                            fill="url(#recentBlocksGradient)"
                            dot={{ fill: '#4d81ea', r: 3 }}
                            activeDot={{
                                fill: '#4d81ea',
                                stroke: '#fff',
                                strokeWidth: 2,
                                r: 4
                            }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default RecentBlocksChart;
