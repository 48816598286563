import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    Tooltip,
    ResponsiveContainer,
    CartesianGrid
} from 'recharts';
import { formatLargeNumber } from '../utils/utils';
import api_url from "../../config/api_and_url.json";

const base_api = api_url.base_api;
const API_URL = base_api + '/validator_stake_jailed_data';

// Static function to format date
const formatDate = (date) => {
    return date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric'
    });
};

const ValidatorStakeChart = () => {
    const [data, setData] = useState([]);
    const [selectedValidators, setSelectedValidators] = useState(new Set());
    const [showAllValidators, setShowAllValidators] = useState(true);
    const [loading, setLoading] = useState(true);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 750);
    const [showChart, setShowChart] = useState(true);

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth <= 750);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const processTimeSeriesData = useCallback((validators) => {
        const groupedByTime = {};
        validators.forEach(validator => {
            const timestamp = new Date(validator.date_time_UTC).getTime();
            if (!groupedByTime[timestamp]) {
                groupedByTime[timestamp] = {
                    timestamp,
                    timeStr: formatDate(new Date(validator.date_time_UTC)),
                    stakeDistribution: {}
                };
            }
            groupedByTime[timestamp].stakeDistribution[validator.name] = parseFloat(validator.stake) / 1e5;
        });
        return Object.values(groupedByTime).sort((a, b) => a.timestamp - b.timestamp);
    }, []);

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const response = await fetch(API_URL, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const jsonData = await response.json();
            const processedData = processTimeSeriesData(jsonData.data);
            setData(processedData);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, [processTimeSeriesData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const validatorNames = useMemo(() => {
        if (!data.length) return [];
        const names = new Set();
        data.forEach(point => {
            Object.keys(point.stakeDistribution).forEach(name => names.add(name));
        });
        return Array.from(names);
    }, [data]);

    const getValidatorColor = (index) => {
        const colors = [
            'var(--primary-color)',
            'var(--highlight-color)',
            'var(--positive-heighlight-color)',
            'var(--negative-heighlight-color)',
            'var(--warning-color)',
            '#9370DB', '#20B2AA', '#FF69B4', '#00CED1', '#FFD700'
        ];
        return colors[index % colors.length];
    };

    const toggleValidator = (validatorName) => {
        setSelectedValidators(prev => {
            const newSelected = new Set(prev);
            if (prev.has(validatorName)) {
                newSelected.delete(validatorName);
            } else {
                newSelected.add(validatorName);
            }
            return newSelected;
        });
        setShowAllValidators(false);
    };

    const toggleAllValidators = () => {
        setSelectedValidators(new Set());
        setShowAllValidators(true);
    };

    const CustomTooltip = ({ active, payload, label, coordinateProps }) => {
        if (active && payload && payload.length) {
            const sortedPayload = [...payload].sort((a, b) => b.value - a.value);

            // Split data into columns
            const midPoint = Math.ceil(sortedPayload.length / 3);
            const firstColumn = sortedPayload.slice(0, midPoint);
            const secondColumn = sortedPayload.slice(midPoint, midPoint * 2);
            const thirdColumn = sortedPayload.slice(midPoint * 2);

            // Calculate total stake
            const totalStake = sortedPayload.reduce((sum, entry) => sum + (entry.value || 0), 0);

            const formatValue = (value) => {
                return new Intl.NumberFormat('en-US', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                }).format(value);
            };

            // Get viewport dimensions for positioning
            const tooltipWidth = 600;
            const tooltipHeight = 300;
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const cursorX = coordinateProps?.x || 0;
            const cursorY = coordinateProps?.y || 0;

            let tooltipStyle = {
                position: 'fixed',
                zIndex: 1000,
                left: Math.min(Math.max(0, cursorX - tooltipWidth / 2), viewportWidth - tooltipWidth),
                top: cursorY + tooltipHeight + 40 > viewportHeight ? cursorY - tooltipHeight : cursorY + 10,
                backgroundColor: 'var(--card-background-color)',
                border: '1px solid var(--border-color)',
                borderRadius: '4px',
                padding: '8px',
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                opacity: 0.95,
                fontSize: '11px',
                width: tooltipWidth
            };

            return (
                <div style={tooltipStyle}>
                    <p style={{
                        margin: '0 0 4px 0',
                        color: 'var(--text-color)',
                        borderBottom: '1px solid var(--border-color)',
                        paddingBottom: '4px',
                        fontSize: '12px',
                        fontWeight: 'bold'
                    }}>
                        {label}
                    </p>
                    <div style={{
                        display: 'flex',
                        gap: '16px',
                        justifyContent: 'space-between'
                    }}>
                        {/* First Column */}
                        <div style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr auto',
                            gap: '2px 8px',
                            flex: 1
                        }}>
                            {firstColumn.map((entry, index) => (
                                <React.Fragment key={`col1-${index}`}>
                                    <span style={{
                                        color: entry.color,
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '120px'
                                    }}>
                                        {entry.dataKey.split('.')[1]}
                                    </span>
                                    <span style={{
                                        color: entry.color,
                                        textAlign: 'right',
                                        fontWeight: '500'
                                    }}>
                                        {formatValue(entry.value)}
                                    </span>
                                </React.Fragment>
                            ))}
                        </div>
                        {/* Second Column */}
                        {secondColumn.length > 0 && (
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr auto',
                                gap: '2px 8px',
                                flex: 1,
                                borderLeft: '1px solid var(--border-color)',
                                paddingLeft: '16px'
                            }}>
                                {secondColumn.map((entry, index) => (
                                    <React.Fragment key={`col2-${index}`}>
                                        <span style={{
                                            color: entry.color,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '120px'
                                        }}>
                                            {entry.dataKey.split('.')[1]}
                                        </span>
                                        <span style={{
                                            color: entry.color,
                                            textAlign: 'right',
                                            fontWeight: '500'
                                        }}>
                                            {formatValue(entry.value)}
                                        </span>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                        {/* Third Column */}
                        {thirdColumn.length > 0 && (
                            <div style={{
                                display: 'grid',
                                gridTemplateColumns: '1fr auto',
                                gap: '2px 8px',
                                flex: 1,
                                borderLeft: '1px solid var(--border-color)',
                                paddingLeft: '16px'
                            }}>
                                {thirdColumn.map((entry, index) => (
                                    <React.Fragment key={`col3-${index}`}>
                                        <span style={{
                                            color: entry.color,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '120px'
                                        }}>
                                            {entry.dataKey.split('.')[1]}
                                        </span>
                                        <span style={{
                                            color: entry.color,
                                            textAlign: 'right',
                                            fontWeight: '500'
                                        }}>
                                            {formatValue(entry.value)}
                                        </span>
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                    {/* Total Stake Display */}
                    <div style={{
                        marginTop: '4px',
                        paddingTop: '4px',
                        borderTop: '1px solid var(--border-color)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: '11px',
                        fontWeight: 'bold',
                        color: 'var(--text-color)'
                    }}>
                        <span>Total Stake</span>
                        <span>{formatValue(totalStake)}</span>
                    </div>
                </div>
            );
        }
        return null;
    };


    const ValidatorList = () => (
        <div style={{
            width: isMobileView ? '100%' : '200px',
            maxHeight: isMobileView ? 'auto' : '500px',
            overflowY: 'auto',
            borderLeft: '1px solid var(--border-color)',
            padding: '10px'
        }}>
            <div
                style={{
                    marginBottom: '10px',
                    padding: '8px',
                    cursor: 'pointer',
                    backgroundColor: showAllValidators ? 'var(--primary-color)' : 'transparent',
                    borderRadius: '4px',
                    textAlign: 'center'
                }}
                onClick={toggleAllValidators}
            >
                Select All
            </div>
            {validatorNames.map((name, index) => (
                <div
                    key={name}
                    onClick={() => toggleValidator(name)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        cursor: 'pointer',
                        backgroundColor: selectedValidators.has(name) ? 'var(--primary-color)' : 'transparent',
                        borderRadius: '4px',
                        marginBottom: '4px'
                    }}
                >
                    <span
                        style={{
                            width: '12px',
                            height: '12px',
                            backgroundColor: getValidatorColor(index),
                            marginRight: '8px',
                            borderRadius: '2px'
                        }}
                    />
                    <span style={{ fontSize: '14px' }}>{name}</span>
                </div>
            ))}
        </div>
    );

    if (loading && !data.length) {
        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '400px',
                color: 'var(--text-color)'
            }}>
                Loading data...
            </div>
        );
    }

    return (
        <div style={{ display: isMobileView ? 'block' : 'flex', flexDirection: 'row', gap: '20px' }}>
            <div style={{
                border: '1px solid var(--border-color)',
                borderRadius: '8px',
                padding: '20px',
                flex: 1
            }}>
                <div style={{
                    marginBottom: '10px',
                    textAlign: 'center'
                }}>
                    <h3 style={{
                        color: 'var(--text-color)',
                        fontSize: '18px',
                        marginBottom: '8px',
                        fontWeight: '600'
                    }}>
                        Validator Stake Over Time
                    </h3>
                    <p style={{
                        color: 'var(--text-color)',
                        fontSize: '14px',
                        opacity: 0.8,
                        margin: 0
                    }}>
                        Click on validator names to show/hide individual data • Y-axis adjusts automatically
                    </p>
                </div>

                {isMobileView && (
                    <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                        <button
                            style={{
                                backgroundColor: 'var(--primary-color)',
                                color: 'white',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                cursor: 'pointer'
                            }}
                            onClick={() => setShowChart(!showChart)}
                        >
                            {showChart ? 'Show Validators' : 'Show Chart'}
                        </button>
                    </div>
                )}

                <div style={{
                    height: isMobileView ? '300px' : '400px',
                    display: isMobileView && !showChart ? 'none' : 'flex'
                }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                            data={data}
                            margin={{
                                top: 20,
                                right: 30,
                                left: 20,
                                bottom: isMobileView ? 20 : 50
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" stroke="var(--border-color)" />
                            <XAxis
                                dataKey="timeStr"
                                angle={isMobileView ? 0 : -45}
                                textAnchor="end"
                                height={isMobileView ? 20 : 40}
                                interval={isMobileView ? 4 : 0}
                                tick={{ fill: 'var(--text-color)', fontSize: isMobileView ? '10px' : '12px' }}
                            />
                            <YAxis
                                tickFormatter={formatLargeNumber}
                                tick={{ fill: 'var(--text-color)', fontSize: isMobileView ? '10px' : '12px' }}
                                label={{
                                    value: 'Stake (m)',
                                    angle: -90,
                                    position: 'insideLeft',
                                    fill: 'var(--text-color)',
                                    offset: -10,
                                    style: { textAnchor: 'middle', fontSize: isMobileView ? '10px' : '14px' }
                                }}
                            />
                            <Tooltip content={(props) => <CustomTooltip {...props} coordinateProps={props?.coordinate} />} />
                            {validatorNames.map((name, index) => (
                                (showAllValidators || selectedValidators.has(name)) && (
                                    <Area
                                        key={name}
                                        type="monotone"
                                        dataKey={`stakeDistribution.${name}`}
                                        stackId="1"
                                        stroke={getValidatorColor(index)}
                                        fill={getValidatorColor(index)}
                                        isAnimationActive={false}
                                    />
                                )
                            ))}
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </div>
            {!isMobileView || !showChart ? <ValidatorList /> : null}
        </div>
    );
};

export default ValidatorStakeChart;
