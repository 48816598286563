import React, { useState, useEffect, useMemo } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
    CartesianGrid
} from 'recharts';
import api_url from '../../config/api_and_url.json';

const base_api = api_url.promotheous_api;

const BlockTimeAverageChart = () => {
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedDurations, setSelectedDurations] = useState(new Set());
    const [showAllDurations, setShowAllDurations] = useState(true);

    const durations = useMemo(() => [
        { label: '5m', query: '300 / delta(hl_block_height[5m])', color: '#FF6384', step: 30 },
        { label: '15m', query: '900 / delta(hl_block_height[15m])', color: '#36A2EB', step: 60 },
        { label: '1h', query: '3600 / delta(hl_block_height[1h])', color: '#FFCE56', step: 120 },
        { label: '4h', query: '14400 / delta(hl_block_height[4h])', color: '#4BC0C0', step: 240 },
        { label: '12h', query: '43200 / delta(hl_block_height[12h])', color: '#9966FF', step: 300 }
    ], []);

    const calculateYAxisDomain = () => {
        if (!chartData.length) return [0, 1000];
        let visibleData = [];
        chartData.forEach(point => {
            durations.forEach(({ label }) => {
                if ((showAllDurations || selectedDurations.has(label)) && point[label] !== null) {
                    visibleData.push(point[label]);
                }
            });
        });
        if (!visibleData.length) return [0, 1000];
        const minValue = Math.min(...visibleData);
        const maxValue = Math.max(...visibleData);
        const padding = (maxValue - minValue) * 0.1;
        const roundedMax = Math.ceil((maxValue + padding) / 50) * 50;
        return [0, roundedMax];
    };

    const generateYAxisTicks = () => {
        const [min, max] = calculateYAxisDomain();
        const step = Math.ceil((max - min) / 8 / 50) * 50;
        const ticks = [];
        for (let i = min; i <= max; i += step) {
            ticks.push(i);
        }
        return ticks;
    };

    const dynamicTicks = useMemo(() => generateYAxisTicks(), [chartData, selectedDurations, showAllDurations]);

    const toggleDuration = (duration) => {
        setSelectedDurations(prev => {
            const newSelected = new Set(prev);
            if (prev.has(duration)) {
                newSelected.delete(duration);
            } else {
                newSelected.add(duration);
            }
            return newSelected;
        });
        setShowAllDurations(false);
    };

    const toggleAllDurations = () => {
        setShowAllDurations(true);
        setSelectedDurations(new Set());
    };

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            if (!mounted) return;
            try {
                const endTime = Math.floor(Date.now() / 1000);
                const startTime = endTime - (12 * 60 * 60); // 12 hours ago
                const results = await Promise.all(durations.map(async (duration) => {
                    try {
                        const url = `${base_api}/api/v1/query_range?query=${encodeURIComponent(duration.query)}&start=${startTime}&end=${endTime}&step=${duration.step}`;
                        const response = await fetch(url);
                        const data = await response.json();
                        if (data.status === 'success' && data.data.result.length > 0) {
                            return { duration: duration.label, values: data.data.result[0].values };
                        }
                        return { duration: duration.label, values: [] };
                    } catch (error) {
                        console.error(`Error fetching ${duration.label}:`, error);
                        return { duration: duration.label, values: [] };
                    }
                }));
                if (!mounted) return;
                const allTimestamps = new Set();
                results.forEach(({ values }) => {
                    values.forEach(([timestamp]) => {
                        allTimestamps.add(timestamp);
                    });
                });
                const valueMap = {};
                results.forEach(({ duration, values }) => {
                    valueMap[duration] = new Map(values.map(([timestamp, value]) => [timestamp, value]));
                });
                const processedData = Array.from(allTimestamps).map(timestamp => {
                    const timeKey = new Date(timestamp * 1000).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit'
                    });
                    const point = { timestamp: timeKey, unix: timestamp * 1000 };
                    durations.forEach(({ label }) => {
                        const value = valueMap[label].get(timestamp);
                        const parsedValue = value ? parseFloat(value) * 1000 : null;
                        point[label] = (parsedValue && !isNaN(parsedValue) && parsedValue <= 1000) ? parsedValue : null;
                    });
                    return point;
                });
                const sortedData = processedData.sort((a, b) => a.unix - b.unix);
                if (mounted) {
                    setChartData(sortedData);
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                if (mounted) setLoading(false);
            }
        };
        fetchData();
        const interval = setInterval(fetchData, 15000);
        return () => {
            mounted = false;
            clearInterval(interval);
        };
    }, [durations]);

    const formatYAxis = (value) => {
        if (value >= 1000) {
            return `${(value / 1000).toFixed(2)}s`;
        }
        return `${value}ms`;
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: '#000',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    borderRadius: '4px',
                    padding: '12px',
                    color: '#fff'
                }}>
                    <p style={{
                        margin: '0 0 8px 0',
                        borderBottom: '1px solid rgba(255, 255, 255, 0.2)',
                        paddingBottom: '4px',
                        fontFamily: 'monospace'
                    }}>
                        {label}
                    </p>
                    {durations.map((duration) => {
                        const dataPoint = payload.find(p => p.dataKey === duration.label);
                        if (showAllDurations || selectedDurations.has(duration.label)) {
                            return (
                                <p key={duration.label} style={{
                                    color: duration.color,
                                    fontSize: '14px',
                                    margin: '4px 0',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    fontFamily: 'monospace',
                                    opacity: dataPoint?.value === null ? 0.5 : 1
                                }}>
                                    <span style={{ marginRight: '12px' }}>{duration.label}:</span>
                                    <span>
                                        {dataPoint?.value !== null && dataPoint?.value !== undefined
                                            ? `${dataPoint.value.toFixed(2)} ms`
                                            : 'N/A'}
                                    </span>
                                </p>
                            );
                        }
                        return null;
                    })}
                </div>
            );
        }
        return null;
    };

    const CustomLegend = () => {
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
                padding: '10px 0',
                color: '#fff',
                flexWrap: 'wrap'
            }}>
                <div
                    onClick={toggleAllDurations}
                    style={{
                        cursor: 'pointer',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        backgroundColor: showAllDurations ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                        border: '1px solid rgba(255, 255, 255, 0.2)',
                        transition: 'all 0.2s',
                        fontSize: '12px'
                    }}
                >
                    Select All
                </div>
                {durations.map((duration) => (
                    <div
                        key={duration.label}
                        onClick={() => toggleDuration(duration.label)}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: '4px 8px',
                            borderRadius: '4px',
                            backgroundColor: (!showAllDurations && selectedDurations.has(duration.label))
                                ? 'rgba(255, 255, 255, 0.1)'
                                : 'transparent',
                            border: '1px solid rgba(255, 255, 255, 0.2)',
                            transition: 'all 0.2s',
                            fontSize: '12px'
                        }}
                    >
                        <span
                            style={{
                                width: '6px',
                                height: '6px',
                                backgroundColor: duration.color,
                                borderRadius: '50%',
                                marginRight: '4px'
                            }}
                        />
                        {duration.label}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div style={{
            width: '100%',
            height: window.innerWidth < 768 ? '400px' : '500px',
            backgroundColor: '#000',
            padding: window.innerWidth < 768 ? '10px' : '20px',
            borderRadius: '8px',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <div style={{
                marginBottom: '15px',
                textAlign: 'center'
            }}>
                <h3 style={{
                    color: '#fff',
                    fontSize: window.innerWidth < 768 ? '14px' : '16px',
                    fontWeight: 'normal',
                    marginBottom: '5px'
                }}>
                    Block Time Averages (12H Window)
                </h3>
                <p style={{
                    color: 'rgba(255, 255, 255, 0.6)',
                    fontSize: window.innerWidth < 768 ? '10px' : '12px',
                    margin: 0
                }}>
                    Click on duration labels to show/hide • Y-axis adjusts automatically
                </p>
            </div>
            {loading && (
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    padding: '10px 20px',
                    borderRadius: '4px',
                    color: '#fff',
                    zIndex: 1000
                }}>
                    Loading...
                </div>
            )}
            <ResponsiveContainer width="100%" height="90%">
                <LineChart
                    data={chartData}
                    margin={{
                        top: 45,
                        right: 10,
                        left: 20,
                        bottom: 30
                    }}
                // window.innerWidth < 768 ? 30 : 20,
                >
                    <CartesianGrid
                        strokeDasharray="3 3"
                        vertical={false}
                        stroke="rgba(255, 255, 255, 0.1)"
                    />
                    <XAxis
                        dataKey="timestamp"
                        tick={{ fill: '#fff', fontSize: window.innerWidth < 768 ? 10 : 12 }}
                        height={window.innerWidth < 768 ? 40 : 60}
                        angle={-45}
                        textAnchor="end"
                        interval="preserveStartEnd"
                        minTickGap={20}
                    />
                    <YAxis
                        tick={{ fill: '#fff' }}
                        tickFormatter={formatYAxis}
                        ticks={dynamicTicks}
                        domain={calculateYAxisDomain()}
                        axisLine={{ stroke: 'rgba(255, 255, 255, 0.2)' }}
                        label={{
                            value: 'Duration (ms)',
                            angle: -90,
                            position: 'insideLeft',
                            offset: -10,
                            style: { fill: '#fff' }
                        }}
                    />
                    <Tooltip content={<CustomTooltip />} />
                    <Legend content={<CustomLegend />} />
                    {durations.map((duration) => {
                        if (showAllDurations || selectedDurations.has(duration.label)) {
                            return (
                                <Line
                                    key={duration.label}
                                    type="monotone"
                                    dataKey={duration.label}
                                    name={duration.label}
                                    stroke={duration.color}
                                    strokeWidth={2}
                                    dot={false}
                                    connectNulls={true}
                                    activeDot={{ r: 4 }}
                                />
                            );
                        }
                        return null;
                    })}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BlockTimeAverageChart;
