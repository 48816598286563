import React, { useState } from 'react'
import TopMetrics from './top_metrics';
import TimeBasedMetrics from './timeBasedMetrics';
import PositionTable from './positionTable';
import BalanceMetrics from './balanceMetrics';
import SymbolMetricsTable from './symbolMetricsTable';
import SpotBalanceTable from './spotBalance_table';
import { ToggleButtonGroup } from '../utils/utils';

const Dashboard = ({ walletData }) => {
    const [selectedTable, setSelectedTable] = useState({ key: 'symbol_metrics', value: 'Symbol Metrics' });

    const tableOptions = [
        { key: 'symbol_metrics', value: 'Symbol Metrics' },
        { key: 'positions', value: 'Positions' },
        { key: 'spot_balance', value: 'Spot Balances' },
    ];

    console.log("walletData::", walletData)
    return (
        <>
            <div>
                <BalanceMetrics walletData={walletData} />
                <TopMetrics walletData={walletData} />
                <div className="time_main_grid">
                    <TimeBasedMetrics walletData={walletData} />
                    <div>
                        <ToggleButtonGroup
                            options={tableOptions}
                            selectedOption={selectedTable}
                            setSelectedOption={setSelectedTable}
                        />
                        {selectedTable.key === 'positions' &&
                            <PositionTable positions_metrics={walletData.balances.positions} />}
                        {selectedTable.key === 'symbol_metrics' &&
                            <SymbolMetricsTable symbol_wise_metrics={walletData.symbol_wise_metrics} />
                        }
                        {selectedTable.key === 'spot_balance' &&
                            <SpotBalanceTable spotBalance_metrics={walletData.balances.spot_balances} />
                        }
                    </div>
                </div>
            </div>

        </>
    );
};

export default Dashboard;