import React, { useState, useEffect } from 'react';
import "./../../css/validators_dasboard/validator_table.css";
import ValidatorCharts from './ValidatorCharts';
import RecentBlocksChart from './RecentBlocksChart';
// import DurationHeatmap from './DurationHeatmap';
// import BlockHeightChart from './block_height_chart.jsx';
import BlockTimeAverageChart from './blockTimeAverageChart.jsx';
import ValidatorDashboard from './ValidatorDashboard';
import ProposalRateChart from './proposalRateChart.jsx';
import ValidatorStakeChart from './validatorStakeChart.jsx';
import BlockApplyDurationChart from './blockApplyDurationChart.jsx';
import ValidatorJailedOverTimeMetricsCharts from './validator_jailed_chart.jsx';

import api_url from "../../config/api_and_url.json"

import "./../../css/validators_dasboard/validator_table.css";

const base_api = api_url.hyperliquid_api
const API_URL = base_api + '/info';

const ValidatorTable = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(API_URL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 'validatorSummaries'
                    })
                });

                const result = await response.json();
                setData(result);
            } catch (err) {
                setError('Error fetching validator data');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) return <div className="validator_metrics_loader_card">Loading data...</div>;
    if (error) return <div className="validator_metrics_error_card">Error: {error}</div>;

    const totalStake = data.reduce((sum, v) => sum + v.stake, 0);


    return (
        <>
            <div className="validator_dashBoard_head">
                <div className="validator_wallet_note">
                    <h4>
                        Validator statistics and performance metrics for the Hyperliquid network
                    </h4>
                </div>
            </div>
            <ValidatorDashboard />
            <br />
            <br />
            <div className='all_validators_charts_main_grid'>
                <ValidatorCharts data={data} total_stake={totalStake} />
                <RecentBlocksChart data={data} />
            </div>
            <ProposalRateChart />
            {/* <br />
            <br />
            <BlockHeightChart /> */}
            <br />
            <br />
            <div className='all_validators_charts_main_grid'>
                {/* <DurationHeatmap /> */}
                <BlockApplyDurationChart />
                <BlockTimeAverageChart />
            </div>
            <ValidatorStakeChart />
            <br />
            <br />
            <ValidatorJailedOverTimeMetricsCharts />



        </>
    );
};

export default ValidatorTable;