import React, { useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Cell
} from 'recharts';

import { formatLargeNumber } from '../utils/utils';

const ValidatorCharts = ({ data, total_stake }) => {
    const [activeIndex, setActiveIndex] = useState(null); // Track which bar is hovered

    const barChartData = data.map(item => ({
        name: item.name || item.validator.slice(0, 6),
        stake: item.stake / 1e5,
        originalStake: item.stake
    })).sort((a, b) => b.stake - a.stake);

    const formatYAxis = (value) => {
        if (value === 0) return '0';
        if (Math.abs(value) >= 1e6) return (value / 1e6).toFixed(0) + 'M';
        if (Math.abs(value) >= 1e3) return (value / 1e3).toFixed(0) + 'K';
        return value.toFixed(0);
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{
                    backgroundColor: '#000',
                    border: '1px solid rgba(255, 255, 255, 0.2)',
                    borderRadius: '4px',
                    padding: '12px',
                    maxWidth: window.innerWidth < 640 ? '150px' : '200px',
                }}>
                    <p style={{
                        margin: '0',
                        color: '#fff',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        wordBreak: 'break-word'
                    }}>{label}</p>
                    <p style={{
                        margin: '4px 0 0 0',
                        color: '#4d81ea',
                        fontSize: '12px'
                    }}>
                        {`Stake: ${payload[0].payload.stake.toLocaleString()}`}
                    </p>
                </div>
            );
        }
        return null;
    };

    const CustomLegend = () => (
        <div className="chart-legend">
            <span style={{
                display: 'inline-block',
                width: '12px',
                height: '12px',
                backgroundColor: '#4d81ea',
                marginRight: '6px'
            }}></span>
            Total_Stake : {formatLargeNumber(total_stake / 1e5)}
        </div>
    );

    const chartConfig = {
        margin: {
            top: 20,
            right: 10,
            bottom: 100,
            left: 40
        },
        xAxisConfig: {
            angle: window.innerWidth < 640 ? -45 : -30,
            textAnchor: "end",
            interval: window.innerWidth < 640 ? 1 : 0,
            height: 50,
            tick: {
                fill: '#fff',
                fontSize: window.innerWidth < 640 ? 6 : 8,
                dy: 8
            }
        },
        yAxisConfig: {
            tick: {
                fill: '#fff',
                fontSize: 10
            },
            width: 45
        }
    };

    // Handlers for hover state
    const handleMouseEnter = (index) => setActiveIndex(index);
    const handleMouseLeave = () => setActiveIndex(null);

    return (
        <div className="all_validators_chart-container">
            <h3 style={{
                color: '#fff',
                marginBottom: '20px',
                fontSize: '14px',
                fontWeight: 'normal',
                textAlign: 'center',
            }}>
                Validator Stake Distribution
            </h3>
            <CustomLegend />
            <div style={{ width: '100%', height: 'calc(100% - 80px)', marginTop: '30px' }}>
                <ResponsiveContainer>
                    <BarChart
                        data={barChartData}
                        margin={chartConfig.margin}
                    >
                        <CartesianGrid
                            strokeDasharray="3 3"
                            vertical={false}
                            stroke="rgba(255, 255, 255, 0.1)"
                        />
                        <XAxis
                            dataKey="name"
                            {...chartConfig.xAxisConfig}
                        />
                        <YAxis
                            {...chartConfig.yAxisConfig}
                            tickFormatter={formatYAxis}
                        />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar
                            dataKey="stake"
                            onMouseLeave={handleMouseLeave}
                        >
                            {barChartData.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={index === activeIndex ? '#1a73e8' : '#4d81ea'}
                                    onMouseEnter={() => handleMouseEnter(index)}
                                />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default ValidatorCharts;
