import React, { useState, useEffect } from 'react';
import { Select, Input, Button, Space, DatePicker } from 'antd';
import moment from 'moment';
import "./../../css/all_metrics/all_metrics.css"
import "./../../css/all_metrics/antd_element.css"

const { Option } = Select;

// const DataFormat = "DD/MM/YY HH:mm"
const DataFormat = "YYYY/MM/DD HH:mm"
// const DataFormat = "YYYY/MM/DD HH:mm:ss"

const WalletFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const [inputValue, setInputValue] = useState(selectedKeys[0] || '');

    useEffect(() => {
        setInputValue(selectedKeys[0] || '');
    }, [selectedKeys]);

    const handleConfirm = () => {
        if (inputValue) {
            setSelectedKeys([inputValue]);
            confirm();
        }
    };

    const handleReset = () => {
        clearFilters();
        setInputValue('');
        setSelectedKeys([]);
        confirm();
    };

    return (
        <div style={{ padding: 8, backgroundColor: 'var(--background-color)', border: '1px solid var(--border-color)' }}>
            <Input
                placeholder="Enter wallet address"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onPressEnter={handleConfirm}
                style={{ width: '100%', marginBottom: 8 }}
            />
            <div className="button-group">
                <Button onClick={handleConfirm} size="small">
                    OK
                </Button>
                <Button onClick={handleReset} type="warning" size="small">
                    Reset
                </Button>
            </div>
        </div>
    );
};

const SymbolFilterDropdown = ({ symbols, setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const handleReset = () => {
        clearFilters();
        setSelectedKeys([]);
        confirm();
    };

    return (
        <div style={{ padding: 8, backgroundColor: 'var(--background-color)', border: '1px solid var(--border-color)' }}>
            <Select
                style={{ width: '100%', marginBottom: 8 }}
                placeholder="Select symbol"
                value={selectedKeys[0]}
                onChange={(value) => setSelectedKeys(value ? [value] : [])}
            >
                {symbols.map(symbol => (
                    <Option key={symbol} value={symbol}>{symbol}</Option>
                ))}
            </Select>
            <div className="button-group">
                <Button onClick={confirm} size="small">
                    OK
                </Button>
                <Button onClick={handleReset} type="warning" size="small">
                    Reset
                </Button>
            </div>
        </div>
    );
};

const DateFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const [comparison, setComparison] = useState('before');
    const [dateTime, setDateTime] = useState(null);

    useEffect(() => {
        if (selectedKeys[0]) {
            const [comp, date] = selectedKeys[0].split(',');
            setComparison(comp);
            setDateTime(moment(date, DataFormat));
        }
    }, [selectedKeys]);

    const handleFilter = () => {
        if (dateTime) {
            setSelectedKeys([`${comparison},${dateTime.format(DataFormat)}`]);
            confirm();
        }
    };

    const handleReset = () => {
        clearFilters();
        setComparison('before');
        setDateTime(null);
        setSelectedKeys([]);
        confirm();
    };

    return (
        <div style={{ padding: 8, backgroundColor: 'var(--background-color)', border: '1px solid var(--border-color)' }}>
            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <Select value={comparison} onChange={setComparison} style={{ width: '100%' }}>
                    <Option value="before">Before</Option>
                    <Option value="after">After</Option>
                    <Option value="on">On</Option>
                </Select>
                <DatePicker
                    showTime
                    value={dateTime}
                    onChange={setDateTime}
                    style={{ width: '100%' }}
                    format={DataFormat}
                />
                <div className="button-group">
                    <Button onClick={handleFilter} size="small">
                        OK
                    </Button>
                    <Button onClick={handleReset} type="warning" size="small">
                        Reset
                    </Button>
                </div>
            </Space>
        </div>
    );
};

const NumericFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    const [comparison, setComparison] = useState('gt');
    const [value, setValue] = useState('');

    useEffect(() => {
        if (selectedKeys[0]) {
            const [comp, val] = selectedKeys[0].split(',');
            setComparison(comp);
            setValue(val);
        }
    }, [selectedKeys]);

    const handleFilter = () => {
        if (value) {
            setSelectedKeys([`${comparison},${value}`]);
            confirm();
        }
    };

    const handleReset = () => {
        clearFilters();
        setComparison('gt');
        setValue('');
        setSelectedKeys([]);
        confirm();
    };

    return (
        <div style={{ padding: 8, backgroundColor: 'var(--background-color)', border: '1px solid var(--border-color)' }}>
            <Space direction="vertical" size={8} style={{ width: '100%' }}>
                <Select value={comparison} onChange={setComparison} style={{ width: '100%' }}>
                    <Option value="gt">Greater than</Option>
                    <Option value="lt">Less than</Option>
                    <Option value="eq">Equal to</Option>
                    <Option value="lte">Less than or equal to</Option>
                </Select>
                <Input
                    placeholder="Enter value"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    style={{ width: '100%' }}
                />
                <div className="button-group">
                    <Button onClick={handleFilter} size="small">
                        OK
                    </Button>
                    <Button onClick={handleReset} type="warning" size="small">
                        Reset
                    </Button>
                </div>
            </Space>
        </div>
    );
};

const TextFilterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, placeholder }) => {
    const [inputValue, setInputValue] = useState(selectedKeys[0] || '');

    useEffect(() => {
        setInputValue(selectedKeys[0] || '');
    }, [selectedKeys]);

    const handleConfirm = () => {
        if (inputValue) {
            setSelectedKeys([inputValue]);
            confirm();
        }
    };

    const handleReset = () => {
        clearFilters();
        setInputValue('');
        setSelectedKeys([]);
        confirm();
    };

    return (
        <div style={{ padding: 8, backgroundColor: 'var(--background-color)', border: '1px solid var(--border-color)' }}>
            <Input
                placeholder={placeholder}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onPressEnter={handleConfirm}
                style={{ width: '100%', marginBottom: 8 }}
            />
            <div className="button-group">
                <Button onClick={handleConfirm} size="small">
                    OK
                </Button>
                <Button onClick={handleReset} type="warning" size="small">
                    Reset
                </Button>
            </div>
        </div>
    );
};

export { WalletFilterDropdown, SymbolFilterDropdown, DateFilterDropdown, NumericFilterDropdown, TextFilterDropdown };
