import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { Select, Checkbox, Space, Tooltip, Table } from 'antd';
import { FilterFilled, CopyOutlined, LinkOutlined, DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import CountUp from 'react-countup';
import { CSVLink } from "react-csv";
import { formatNumber, symbol_dict, formatLargeNumber } from '../utils/utils';
import { WalletFilterDropdown, SymbolFilterDropdown, DateFilterDropdown, NumericFilterDropdown } from './filter_function.jsx';

import "./../../css/all_metrics/all_metrics.css"
import "./../../css/all_metrics/antd_element.css"

import allMetricsCSV from './../../Data_csv/all_metrics.csv';

const headerMapping = {
  "wallet": "Wallet Address",
  "hold_time(min)": "Hold Time (min)",
  "pct_long_trade": "Long Trades (%)",
  "pct_prof_trade": "Profitable Trades (%)",
  "avg_ret_trade_bps": "Avg. Return per Trade (bps)",
  "total_trades": "Total Trades",
  "pct_long_prof_trade": "Profitable Long Trades (%)",
  "pct_short_prof_trade": "Profitable Short Trades (%)",
  "max_dd": "Max Drawdown",
  "last_7d_pnl": "7-Day PnL",
  "last_30d_pnl": "30-Day PnL",
  "lt_pnl": "Last 10K Tx PnL",
  "most_prof_symbol": "Most Profitable Symbol",
  "most_prof_symbol_abs_pnl": "Most Profitable Symbol PnL",
  "total_turnover": "Total Turnover",
  "mtm/tv": "MTM/TV Ratio",
  "last_trade_time": "Last Trade Time",
  "start_trade_time": "First Trade Time",
  "fee/turnover": "Fee/Turnover Ratio",
  "avg_daily_trade": "Avg. Daily Trades",
  "total_symbols_traded": "Total Symbols Traded"
};

const headerMappingArray = [
  { key: "wallet", label: "Wallet Address" },
  { key: "hold_time(min)", label: "Hold Time (min)" },
  { key: "pct_long_trade", label: "Long Trades (%)" },
  { key: "pct_prof_trade", label: "Profitable Trades (%)" },
  { key: "avg_ret_trade_bps", label: "Avg. Return per Trade (bps)" },
  { key: "total_trades", label: "Total Trades" },
  { key: "pct_long_prof_trade", label: "Profitable Long Trades (%)" },
  { key: "pct_short_prof_trade", label: "Profitable Short Trades (%)" },
  { key: "max_dd", label: "Max Drawdown" },
  { key: "last_7d_pnl", label: "7-Day PnL" },
  { key: "last_30d_pnl", label: "30-Day PnL" },
  { key: "lt_pnl", label: "Last 10K Tx PnL" },
  { key: "most_prof_symbol", label: "Most Profitable Symbol" },
  { key: "most_prof_symbol_abs_pnl", label: "Most Profitable Symbol PnL" },
  { key: "total_turnover", label: "Total Turnover" },
  { key: "mtm/tv", label: "MTM/TV Ratio" },
  { key: "last_trade_time", label: "Last Trade Time" },
  { key: "start_trade_time", label: "First Trade Time" },
  { key: "fee/turnover", label: "Fee/Turnover Ratio" },
  { key: "avg_daily_trade", label: "Avg. Daily Trades" },
  { key: "total_symbols_traded", label: "Total Symbols Traded" }
];

// Define default columns to show
const defaultColumns = [
  'wallet',
  'avg_ret_trade_bps',
  'hold_time(min)',
  'pct_prof_trade',
  'lt_pnl',
  'last_30d_pnl',
  'total_trades',
  'total_turnover',
  'total_symbols_traded'
];

// const DataFormat = "DD/MM/YY HH:mm"
const DataFormat = "YYYY/MM/DD HH:mm"
// const DataFormat = "YYYY/MM/DD HH:mm:ss"

const AllMetricsTable = () => {
  const [data, setData] = useState([]);
  const [showingData, setShowingData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [columnsOption, setColumnsOption] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [symbols, setSymbols] = useState([]);
  const [walletColumn, setWalletColumn] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterParams, setFilterParams] = useState({});

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCSV = async () => {
      try {

        /* version 1 */
        // const response = await fetch(allMetricsCSV);
        // const reader = response.body.getReader();
        // const result = await reader.read();
        // const decoder = new TextDecoder('utf-8');
        // const csv = decoder.decode(result.value);


        /* version 2 */
        const response = await fetch(allMetricsCSV);
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');
        let csv = '';

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
          csv += decoder.decode(value, { stream: true });
        }

        // const text = await response.text();

        // console.log("ass", response)
        // console.log("reader", reader)
        // // console.log("result", result)
        // console.log("text", csv)

        // Check if the CSV is empty
        if (csv.trim() === '') {
          console.log('The CSV file is empty.');
          setData([]);
          setShowingData([]);
          return;
        }

        Papa.parse(csv, {
          header: true,
          dynamicTyping: true,
          skipEmptyLines: true, // Skip empty lines
          complete: (results) => {
            // const parsedData = results.data;
            // Check if there's data beyond headers

            if (results.data.length === 0) {
              console.log('The CSV file contains only headers.');
              setData([]);
              setShowingData([]);
              return;
            }

            const parsedData = results.data.map(item => ({
              ...item,
              last_trade_time: moment(item.last_trade_time, DataFormat).toDate(),
              start_trade_time: moment(item.start_trade_time, DataFormat).toDate()
            }));
            // console.log("parsed data", parsedData[1])




            // Create a Map to store unique wallet addresses
            const uniqueWalletsMap = new Map();

            parsedData.forEach(item => {
              if (!uniqueWalletsMap.has(item.wallet) || item.lt_pnl > uniqueWalletsMap.get(item.wallet).lt_pnl) {
                uniqueWalletsMap.set(item.wallet, item);
              }
            });

            // Convert the Map back to an array
            const uniqueWalletsData = Array.from(uniqueWalletsMap.values());

            // Sort the unique data by lt_pnl
            const sortedData = uniqueWalletsData.sort((a, b) => b.lt_pnl - a.lt_pnl);



            // const sortedData = [...parsedData].sort((a, b) => b.lt_pnl - a.lt_pnl);

            const data_refactor = sortedData.map(item => ({
              ...item,
              most_prof_symbol: symbol_dict[item.most_prof_symbol] ? symbol_dict[item.most_prof_symbol].split("/")[0] : item.most_prof_symbol.split("/")[0]
            }));

            setData(data_refactor);
            setShowingData(data_refactor)

            // Extract unique symbols
            const uniqueSymbols = [...new Set(data_refactor.map(item => item.most_prof_symbol))].filter(Boolean);
            // setSymbols(uniqueSymbols);

            const csvColumns = Object.keys(data_refactor[0])
              .map((key) => {
                let column = {
                  className: 'tableColumn',
                  title: headerMapping[key] || key,
                  dataIndex: key,
                  key: key,
                  align: 'center',
                  width: 170,
                  fixed: key === 'wallet' ? 'left' : false,
                  // ellipsis: true,
                  sorter: (a, b) => {
                    if (typeof a[key] === 'number' && typeof b[key] === 'number') {
                      return a[key] - b[key];
                    }
                    else if (key === 'last_trade_time' || key === 'start_trade_time') {
                      // return moment(a[key]).unix() - moment(b[key]).unix();
                      return a[key] - b[key];
                    } else {
                      return (a[key] || '').localeCompare(b[key] || '');
                    }
                  },
                  sortDirections: ['descend', 'ascend'],
                  render: (text, record) => {
                    if (key === 'wallet') {
                      return (
                        <Space>
                          <Tooltip title={text}>
                            {shortenAddress(text)}
                          </Tooltip>
                          <Tooltip title="Copy to clipboard">
                            <CopyOutlined onClick={() => copyToClipboard(text)} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                          <Tooltip title="View portfolio">
                            <LinkOutlined onClick={() => window.open(`/portfolio/?address=${text}`, '_blank')} style={{ cursor: 'pointer' }} />
                          </Tooltip>
                        </Space>
                      );
                    }
                    if (key === 'most_prof_symbol') {
                      return text; // Return most_prof_symbol as-is
                    }

                    if (key === 'last_trade_time' || key === 'start_trade_time') {
                      return moment(text).format(DataFormat);
                      // return text; // Return most_prof_symbol as-is
                    }
                    const value = parseFloat(text);
                    if (!isNaN(value)) {
                      // return value.toFixed(2);
                      if (key === 'max_dd' || key === 'last_7d_pnl' || key === 'last_30d_pnl' || key === 'lt_pnl' || key === 'most_prof_symbol_abs_pnl' || key === 'total_turnover') {
                        return formatLargeNumber(value)
                      }
                      return formatNumber(value)

                    }
                    return text;
                  },
                };

                // Custom filter for wallet address
                if (key === 'wallet') {
                  column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <WalletFilterDropdown
                      setSelectedKeys={setSelectedKeys}
                      selectedKeys={selectedKeys}
                      confirm={confirm}
                      clearFilters={clearFilters}
                    />
                  );
                  column.onFilter = (value, record) => record.wallet.toLowerCase().includes(value.toLowerCase());
                }

                // Custom filter for most profitable symbol
                else if (key === 'most_prof_symbol') {
                  column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <SymbolFilterDropdown
                      symbols={uniqueSymbols}
                      setSelectedKeys={setSelectedKeys}
                      selectedKeys={selectedKeys}
                      confirm={confirm}
                      clearFilters={clearFilters}
                    />
                  );
                  column.onFilter = (value, record) => record.most_prof_symbol === value;
                }

                // Custom filter for date columns
                else if (key === 'start_trade_time' || key === 'last_trade_time') {
                  column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <DateFilterDropdown
                      setSelectedKeys={setSelectedKeys}
                      selectedKeys={selectedKeys}
                      confirm={confirm}
                      clearFilters={clearFilters}
                    />
                  );
                  column.onFilter = (value, record) => {
                    const [comparison, dateString] = value.split(',');
                    const recordDate = moment(record[key]);
                    // const filterDate = moment(dateString);
                    const filterDate = moment(dateString, "YYYY-MM-DD HH:mm:ss");

                    switch (comparison) {
                      case 'before':
                        return recordDate.isBefore(filterDate);
                      case 'after':
                        return recordDate.isAfter(filterDate);
                      case 'on':
                        return recordDate.isSame(filterDate, 'day');
                      default:
                        return true;
                    }
                  };
                }

                // Default numeric filter for other columns
                else {
                  column.filterDropdown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                    <NumericFilterDropdown
                      setSelectedKeys={setSelectedKeys}
                      selectedKeys={selectedKeys}
                      confirm={confirm}
                      clearFilters={clearFilters}
                    />
                  );
                  column.onFilter = (value, record) => {
                    const [comparison, filterValue] = value.split(',');
                    const recordValue = parseFloat(record[key]);
                    const filterNumber = parseFloat(filterValue);

                    switch (comparison) {
                      case 'gt':
                        return recordValue > filterNumber;
                      case 'lt':
                        return recordValue < filterNumber;
                      case 'eq':
                        return recordValue === filterNumber;
                      case 'lte':
                        return recordValue <= filterNumber;
                      default:
                        return true;
                    }
                  };
                }

                column.filterIcon = filtered => <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />;

                return column;
              });
            const walletCol = csvColumns.find(col => col.key === 'wallet');
            const otherCols = csvColumns.filter(col => col.key !== 'wallet');

            setWalletColumn(walletCol);
            setColumnsOption(otherCols)
            setColumns(csvColumns);
            // setSelectedColumns(otherCols.map(col => col.key));

            // Set selected columns to default columns
            setSelectedColumns(defaultColumns);

            const params = queryString.parse(location.search);
            if (params.columns) {
              const urlColumns = params.columns.split(',');
              setSelectedColumns(urlColumns);
            } else {
              setSelectedColumns(defaultColumns);
            }
            if (params.filters) {
              const urlFilters = JSON.parse(params.filters);
              applyFiltersFromURL(data_refactor, urlFilters, csvColumns);
            }
          },
          error: (error) => {
            setError('Error parsing CSV: ' + error.message);
          }
        });
      } catch (error) {
        setError('Error reading CSV file: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCSV();
  }, [location.search]);

  const applyFiltersFromURL = (data, urlFilters, columns) => {
    let filteredData = [...data];
    const newFilters = {};

    Object.entries(urlFilters).forEach(([key, value]) => {
      const column = columns.find(col => col.key === key);
      if (column && column.onFilter) {
        filteredData = filteredData.filter(item => column.onFilter(value, item));
        newFilters[key] = value;
      }
    });

    setFilterParams(newFilters);
    setShowingData(filteredData);
  };

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (params.sort) {
      const [field, order] = params.sort.split(',');
      setSortField(field);
      setSortOrder(order);
    }
    if (params.filters) {
      setFilterParams(JSON.parse(params.filters));
    }
    if (params.columns) {
      const urlColumns = params.columns.split(',');
      setSelectedColumns(urlColumns);
    }
  }, [location.search]);

  const shortenAddress = (address) => {
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      // You can add a notification here if you want to inform the user that the text was copied
      console.log('Copied to clipboard');
    });
  };

  const handleColumnChange = (columnKey, isAdding) => {
    const newSelectedColumns = isAdding
      ? [...selectedColumns, columnKey]
      : selectedColumns.filter(key => key !== columnKey);
    setSelectedColumns(newSelectedColumns);
    updateQueryParams(sortField, sortOrder, filterParams, newSelectedColumns);
  };


  const handleTableChange = (pagination, filters, sorter) => {
    const newSortField = sorter.order ? sorter.field : null;
    const newSortOrder = sorter.order ? sorter.order : null;
    const newFilters = {};

    Object.keys(filters).forEach(key => {
      if (filters[key] && filters[key].length > 0) {
        newFilters[key] = filters[key][0];
      }
    });

    setSortField(newSortField);
    setSortOrder(newSortOrder);
    setFilterParams(newFilters);

    updateQueryParams(newSortField, newSortOrder, newFilters, selectedColumns);

    let filteredData = [...data];
    Object.keys(newFilters).forEach(key => {
      const column = columns.find(col => col.key === key);
      if (column && column.onFilter) {
        filteredData = filteredData.filter(item => column.onFilter(newFilters[key], item));
      }
    });

    setShowingData(filteredData);
  };

  const updateQueryParams = (newSortField, newSortOrder, newFilters, newSelectedColumns) => {
    const params = {};
    if (newSortField && newSortOrder) {
      params.sort = `${newSortField},${newSortOrder}`;
    }
    if (Object.keys(newFilters).length > 0) {
      params.filters = JSON.stringify(newFilters);
    }
    if (newSelectedColumns.length > 0) {
      params.columns = newSelectedColumns.join(',');
    }
    navigate(`?${queryString.stringify(params)}`, { replace: true });
  };

  const visibleColumns = walletColumn
    ? [walletColumn, ...columnsOption.filter(col => selectedColumns.includes(col.key))]
    : columns.filter(col => selectedColumns.includes(col.key));


  visibleColumns.forEach(column => {
    if (column.key === sortField) {
      column.defaultSortOrder = sortOrder;
    }
    if (filterParams[column.key]) {
      column.filteredValue = [filterParams[column.key]];
    }
  });

  // Sort the visible columns according to the defaultColumns order
  visibleColumns.sort((a, b) => {
    const indexA = defaultColumns.indexOf(a.key);
    const indexB = defaultColumns.indexOf(b.key);
    if (indexA === -1 && indexB === -1) return 0;
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });


  if (loading) {
    return <div className="metrics_loader_card ">Loading data...</div>;
  }

  if (error) {
    return <div className="metrics_error_card ">Error: {error}</div>;
  }

  // console.log("symbols", symbols)
  // console.log("columns", columns)
  // console.log("selectedColumns", selectedColumns)
  // console.log("loading", loading)
  // console.log("error", error)

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = mm + "/" + dd + "/" + yyyy;

  return (
    <>
      <div className="dashBoard_head">
        <div className="wallet_note">
          <h4>
            We show only realized PnL with assumption of 2 bps fee per trade. Analytics are based on the last 10,000 transactions or all if fewer.
          </h4>
        </div>
      </div>
      <br />
      <div className="all_metrics_main_grid">
        <div className='all_metrics_main_card all_metrics_main_card_content'>
          <div className="all_metrics_content_heading">Total Entries</div>
          <div className="all_metrics_content_value"><CountUp end={data.length} duration={2.5} formattingFn={(value) => formatNumber(value)} /></div>
        </div>
        <div className='all_metrics_main_card all_metrics_main_card_content'>
          <div className="all_metrics_content_heading">Total Entries Shown</div>
          <div className="all_metrics_content_value"><CountUp end={showingData.length} duration={2.5} formattingFn={(value) => formatNumber(value)} /></div>
        </div>
        <div className='all_metrics_main_card all_metrics_main_card_content'>
          <div className="all_metrics_content_heading">Select additional columns to display</div>
          <Select
            mode="multiple"
            placeholder="Select columns to display"
            value={selectedColumns}
            onChange={(values) => {
              setSelectedColumns(values);
              updateQueryParams(sortField, sortOrder, filterParams, values);
            }}
            dropdownRender={menu => (
              <div>
                {columnsOption.map(col => (
                  <div key={col.key} style={{ padding: '8px 12px', borderBottom: '1px solid var(--border-color)' }}>
                    <Checkbox
                      checked={selectedColumns.includes(col.key)}
                      onChange={(e) => handleColumnChange(col.key, e.target.checked)}
                    >
                      {col.title}
                    </Checkbox>
                  </div>
                ))}
              </div>
            )}
            maxTagCount={0}
            maxTagPlaceholder={() => `${selectedColumns.length} out of ${columnsOption.length + 1} columns selected`}
          />
        </div>
        <div className='all_metrics_main_card all_metrics_main_card_content'>
          <div className="all_metrics_content_heading">Export to CSV</div>
          <div className="all_metrics_content_value">
            <CSVLink
              data={showingData}
              headers={headerMappingArray}
              filename={`Hyperliquid_all_metics_${today}.csv`}
            >
              <DownloadOutlined style={{ cursor: 'pointer', color: "var(--primary-color)", fontSize: "43px" }} />
            </CSVLink>
          </div>
        </div>
      </div >
      <div className="table_note">
        Please scroll right to see all columns
      </div>
      <Table
        columns={visibleColumns}
        dataSource={showingData}
        tableLayout='fixed'
        scroll={{ x: '100vw', y: '70vh' }}
        onChange={handleTableChange}
        sortField={sortField}
        sortOrder={sortOrder}
      />
    </>
  );
};

export default AllMetricsTable;
