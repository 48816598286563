// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../index.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    background-color: var(--background-color);
    border-top: 2px solid var(--border-color);
    color:var(--text-color);
    padding: 1rem 0;
  }
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-logo {
    text-decoration: none;
    color: var(--primary-color);
    transition: opacity 0.3s ease;
  }
  .footer-logo:hover {
    opacity: 0.8;
  }
  .footer-logo h1 {
    font-family: var(--logo-font-family);
    font-size: 2em;
    font-weight: 700;
    margin: 0;
  }
  .footer-disclaimer {
    font-size: 0.875rem;
    color: var(--text-color-heading);
  }
  .footer-social {
    display: flex;
    align-items: center;
  }
  .follow-us {
    margin-right: 1rem;
  }
  .social-icon {
    color: var(--text-color);
    margin: 0 0.5rem;
    transition: color 0.3s ease;
  }
  .social-icon:hover {
    color: var(--primary-color);
  }
  @media (max-width: 768px) {
    .footer-content {
      flex-direction: column;
      text-align: center;
    }
    .footer-logo, .footer-disclaimer, .footer-social {
      margin-bottom: 1rem;
    }
  }`, "",{"version":3,"sources":["webpack://./src/css/footer/footer.css"],"names":[],"mappings":"AAEA;IACI,yCAAyC;IACzC,yCAAyC;IACzC,uBAAuB;IACvB,eAAe;EACjB;EACA;IACE,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,qBAAqB;IACrB,2BAA2B;IAC3B,6BAA6B;EAC/B;EACA;IACE,YAAY;EACd;EACA;IACE,oCAAoC;IACpC,cAAc;IACd,gBAAgB;IAChB,SAAS;EACX;EACA;IACE,mBAAmB;IACnB,gCAAgC;EAClC;EACA;IACE,aAAa;IACb,mBAAmB;EACrB;EACA;IACE,kBAAkB;EACpB;EACA;IACE,wBAAwB;IACxB,gBAAgB;IAChB,2BAA2B;EAC7B;EACA;IACE,2BAA2B;EAC7B;EACA;IACE;MACE,sBAAsB;MACtB,kBAAkB;IACpB;IACA;MACE,mBAAmB;IACrB;EACF","sourcesContent":["@import \"./../../index.css\";\n\nfooter {\n    background-color: var(--background-color);\n    border-top: 2px solid var(--border-color);\n    color:var(--text-color);\n    padding: 1rem 0;\n  }\n  .footer-content {\n    max-width: 1200px;\n    margin: 0 auto;\n    padding: 0 1rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n  }\n  .footer-logo {\n    text-decoration: none;\n    color: var(--primary-color);\n    transition: opacity 0.3s ease;\n  }\n  .footer-logo:hover {\n    opacity: 0.8;\n  }\n  .footer-logo h1 {\n    font-family: var(--logo-font-family);\n    font-size: 2em;\n    font-weight: 700;\n    margin: 0;\n  }\n  .footer-disclaimer {\n    font-size: 0.875rem;\n    color: var(--text-color-heading);\n  }\n  .footer-social {\n    display: flex;\n    align-items: center;\n  }\n  .follow-us {\n    margin-right: 1rem;\n  }\n  .social-icon {\n    color: var(--text-color);\n    margin: 0 0.5rem;\n    transition: color 0.3s ease;\n  }\n  .social-icon:hover {\n    color: var(--primary-color);\n  }\n  @media (max-width: 768px) {\n    .footer-content {\n      flex-direction: column;\n      text-align: center;\n    }\n    .footer-logo, .footer-disclaimer, .footer-social {\n      margin-bottom: 1rem;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
