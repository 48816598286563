import React from 'react';

const SemiCircleGauge = ({ percentage, color = "var(--primary-color)" }) => {
    const radius = 80;
    const strokeWidth = 15;
    const normalizedRadius = radius - strokeWidth / 2;
    const circumference = normalizedRadius * Math.PI;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;

    return (
        <div className="gauge-card">
            <div className="gauge">
                <svg
                    width={radius * 2}
                    height={radius + strokeWidth / 2}
                    viewBox={`0 0 ${radius * 2} ${radius + strokeWidth / 2}`}
                >
                    {/* Background semi-circle */}
                    <path
                        d={`M${strokeWidth / 2},${radius} A${normalizedRadius},${normalizedRadius} 0 0,1 ${radius * 2 - strokeWidth / 2},${radius}`}
                        fill="none"
                        stroke="#e6e6e6"
                        strokeWidth={strokeWidth}
                    />
                    {/* Colored semi-circle */}
                    <path
                        d={`M${strokeWidth / 2},${radius} A${normalizedRadius},${normalizedRadius} 0 0,1 ${radius * 2 - strokeWidth / 2},${radius}`}
                        fill="none"
                        stroke={color}
                        strokeWidth={strokeWidth}
                        strokeDasharray={circumference}
                        strokeDashoffset={strokeDashoffset}
                        strokeLinecap="round"
                    />
                    {/* Percentage text */}
                    <text
                        x="50%"
                        y="70%"
                        textAnchor="middle"
                        fontSize="24"
                        fontWeight="bold"
                        fill="white"
                    >
                        {percentage.toFixed(2)}%
                    </text>
                </svg>
            </div>
        </div>
    );
};

export default SemiCircleGauge;