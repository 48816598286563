import React from 'react';
import {
    FaTwitter,
    // FaLinkedin,
    FaTelegramPlane
} from 'react-icons/fa';
import "./../../css/footer/footer.css"

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-logo">
                    <a href="/" className="footer-logo">
                        <h1>ASXN</h1>
                    </a>
                </div>
                <div className="footer-disclaimer">
                    For informational purposes only. This is not professional, financial, or legal advice.
                </div>
                <div className="footer-social">
                    {/* <span className="follow-us">FOLLOW US</span> */}
                    <a href="https://x.com/asxnlabs" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaTwitter size={20} />
                    </a>
                    {/* <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaLinkedin size={20} />
                    </a> */}
                    <a href="https://t.me/+FbZod8E8mHdhYjk8" target="_blank" rel="noopener noreferrer" className="social-icon">
                        <FaTelegramPlane size={20} />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;