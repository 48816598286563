import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Portfolio from './components/dashboard/portfolio';
import AllMetricsTable from './components/all_metrics/allMetricsTable';
import SymbolWiseMetricsTable from './components/all_metrics/symbol_wise_metrics_table';
import VaultWiseMetricsTable from './components/all_metrics/vault_wise_metrics';
import AllValidatorsDetails from './components/validator_dashboard/validator_details';
import Navbar from './components/navbar/navbar';
import Footer from './components/footer/footer';
import "./App.css"

const App = () => {
  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-J3M09EYP8H`; // Replace G-XXXXXXXXXX with your Measurement ID
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-J3M09EYP8H'); // Replace G-XXXXXXXXXX with your Measurement ID
    };

    // Clean up
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <Router>
      <Navbar />
      <div className="main2">
        <Routes>
          <Route path="/all_metrics" element={<AllMetricsTable />} />
          <Route path="/symbol_wise_metrics" element={<SymbolWiseMetricsTable />} />
          <Route path="/vault_metrics" element={<VaultWiseMetricsTable />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/staking" element={<AllValidatorsDetails />} />
          <Route path="/" element={<Navigate replace to="/all_metrics" />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;