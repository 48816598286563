import React, { useMemo } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, LabelList } from 'recharts';
import { formatNumber, symbol_dict } from '../utils/utils';
import './../../css/dashboard/topMetrics.css';

const TopTokenPnLChart = ({ symbol_wise_metrics }) => {
    const chartData = useMemo(() => {
        const processedData = symbol_wise_metrics
            .map(item => ({
                ...item,
                Symbol: symbol_dict[item.Symbol] ? symbol_dict[item.Symbol].split("/")[0] : item.Symbol.split("/")[0]
            }))
            .sort((a, b) => Math.abs(b.PnL) - Math.abs(a.PnL));

        if (processedData.length <= 4) {
            return processedData.map(item => ({
                name: item.Symbol,
                PnL: item.PnL
            }));
        } else {
            const topFour = processedData.slice(0, 4);
            const othersPnL = processedData.slice(4).reduce((sum, item) => sum + item.PnL, 0);
            return [
                ...topFour.map(item => ({ name: item.Symbol, PnL: item.PnL })),
                { name: 'Others', PnL: othersPnL }
            ];
        }
    }, [symbol_wise_metrics]);

    const formatYAxis = (value) => {
        if (Math.abs(value) >= 1e6) {
            return `$${(value / 1e6).toFixed(1)}M`;
        } else if (Math.abs(value) >= 1e3) {
            return `$${(value / 1e3).toFixed(1)}K`;
        }
        return `$${value.toFixed(0)}`;
    };

    const CustomizedLabel = (props) => {
        const { x, y, width, height, value } = props;
        const radius = 10;
        return (
            <g>
                <text
                    x={x + width / 2}
                    y={value >= 0 ? y - radius : y + height + radius}
                    fill="#666"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize="12"
                >
                    {formatYAxis(value)}
                </text>
            </g>
        );
    };

    return (
        <div className='chart_card'>
            <h4 className="chart_heading">Top Symbols PnL</h4>
            <ResponsiveContainer width="100%" height={250}>
                <BarChart
                    data={chartData}
                    margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
                >
                    <XAxis dataKey="name" />
                    <YAxis
                        tickFormatter={formatYAxis}
                        width={80}
                    />
                    <Tooltip
                        formatter={(value) => `$${formatNumber(value)}`}
                        labelFormatter={(label) => `Symbol: ${label}`}
                    />
                    <Legend />
                    <Bar dataKey="PnL" fill="#4d81ea">
                        <LabelList content={<CustomizedLabel />} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default TopTokenPnLChart;